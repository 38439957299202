// ============================================================
// ATTENTION & COMMON RULE!!
// 関数を実装のみ（処理の実行は下部で実行する）
// 関数名には振る舞いを表す英語プラスFuncを付ける
// ============================================================

// レスポンシブ判定 & デバイス判定関数（PC,SP共通処理）
// -------------------------------------------------------------

function mq(device) {
  if (window.matchMedia('(max-width:768px)').matches && device === 'sp') {
    return true;
  } else if (
    window.matchMedia('(min-width:769px)').matches &&
    device === 'pc'
  ) {
    return true;
  }
  return false;
}
// FUNCTIONS

function formCheck(){
  //エラーを表示する関数（error クラスの p 要素を追加して表示）
  function show_error(message, this$) {
    console.log(this$[0].getAttribute('id'));
    var error_text = $('#'+this$[0].id+'_title').val();
    this$.parent().append("<p class='error'>"+error_text+"は必須項目です</p>")
  }
  
  //フォームが送信される際のイベントハンドラの設定
  $("#formPage1").submit(function(){
    //エラー表示の初期化
    $("p.error").remove();
    $("div").removeClass("error");
    var text = "";
    $("#errorDispaly").remove();
    
    // return false;

    //メールアドレスの検証
    var email =  $.trim($("#email").val());
    if(email && !(/^([a-z0-9\+_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/gi).test(email)){
      $("#email").after("<p class='error'>メールアドレスの形式が異なります</p>")
    }
    //確認用メールアドレスの検証
    // var email_check =  $.trim($("#email_check").val());
    // if(email_check && email_check != $.trim($("input[name="+$("#email_check").attr("name").replace(/^(.+)_check$/, "$1")+"]").val())){
    //   show_error("が異なります", $("#email_check"));
    // }
    //電話番号の検証
    var tel = $.trim($("#tel").val());
    if(tel && !(/^\(?\d{2,5}\)?[-(\.\s]{0,2}\d{1,4}[-)\.\s]{0,2}\d{3,4}$/gi).test(tel)){
      $("#tel").after("<p class='error'>電話番号の形式が異なります（半角英数字でご入力ください）</p>")
    }

     //カナの検証
     var kana = $.trim($("#kana").val());
     if(kana && !kana.match(/^[ァ-ヶー　]+$/)){
       $("#kana").after("<p class='error'>全角カタカナではない文字が含まれています</p>")
     }
    
    //1行テキスト入力フォームとテキストエリアの検証
    $(".validate").each(function(){
      //必須項目の検証
      $(this).filter(".required").each(function(){
        // console.log($(this));
        if($(this).val()==""){
          show_error("必須項目です", $(this));
        }
      })  
      //文字数の検証
      $(this).filter(".max30").each(function(){
        if($(this).val().length > 30){
          show_error(" は30文字以内です", $(this));
        }
      })
      $(this).filter(".max50").each(function(){
        if($(this).val().length > 50){
          show_error(" は50文字以内です", $(this));
        }
      })
      $(this).filter(".max100").each(function(){
        if($(this).val().length > 100){
          show_error(" は100文字以内です", $(this));
        }
      })
      $(this).filter(".max1000").each(function(){
        if($(this).val().length > 1000){
          show_error(" は1000文字以内でお願いします", $(this));
        }
      }) 
    })

    //error クラスの追加の処理
    if($("p.error").length > 0){
      // $("p.error").parent().addClass("error");
      $('html,body').animate({ scrollTop: $("p.error:first").offset().top-180 }, 'slow');
      return false;
    }
  })

}

function noIntrofunc() {
  $('body').css('overflow', 'auto');
  $('body').css('height', 'auto');
  $('.header').addClass('show');
  $('.menu-trigger').addClass('show');
  $('.top-menu-contents').addClass('show');
  $('#introWrap').addClass('hide');
  $('.loading').addClass('hide');
  
  $('#themeWrap').addClass('show');
}
function leafFunc() {}
function lazyLoadVideo() {
  var videoPc = document.getElementById('videoTop');
  var videoWrap = document.getElementById('videoWrap');

  var xhr = new XMLHttpRequest();
  xhr.open('GET', '../img/bini.mp4', true);
  xhr.responseType = 'blob';
  console.log(xhr);
  xhr.onload = function(e) {
    console.log(this.status);
    if (this.status === 200) {
      // ロード完了
      var blob = this.response;
      var src = (window.webkitURL || window.URL).createObjectURL(blob);
      console.log('videoPc loaded!!!');
      videoPc.src = '../img/bini.mp4';
      videoPc.pause();
      var videoWrap = document.getElementById('videoWrap');
      videoWrap.classList.add('show');
    }
  };
  xhr.send();
}

function subPageFunc() {
  console.log('SUB PAGE');
  // $('body').css('overflow', 'auto');
  // $('body').css('height', 'auto');
  $('.header').addClass('show');
  $('.menu-trigger').addClass('show');
  $('.main-wrap').addClass('show');

  // if($('#takeoutGallery').length){
  //   var swiper = new Swiper('#takeoutGallery', {
  //     effect: 'fade',
  //     pagination: {
  //       el: '.swiper-pagination'
  //     },
  //     speed: 3000,
  //     autoplay: {
  //       delay: 3000
  //     },
  //     loop: true
  //   });
  // }
}
// function firstViewFunc() {
//   var st0 = document.querySelectorAll('.logo-in-anime .st0');
//   new Vivus(
//     'svg-animation',
//     {
//       type: 'scenario-sync',
//       duration: 50,
//       forceRender: true,
//       animTimingFunction: Vivus.EASE
//     },
//     function() {
//       for (var i = 0; i < st0.length; i++) {
//         st0[i].removeAttribute('style');
//         if (i === st0.length - 1) {
//           console.log('st0.length');
//           myCallback();
//         }
//       }
//     },
//     200
//   );
//   function myCallback() {
//     $('.loading').addClass('hide');
//     $('body').css('overflow', 'auto');
//     $('body').css('height', 'auto');
//     $('.header').addClass('show');
//     $('.top-slider').addClass('show');
//   }
//   // LOADING ANIMATION
//   console.log('TEST');
// }

function headerChangeBg() {
  var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  if (scrollTop < 1) {
    document.getElementById('headerWrap').classList.remove('bg');
  } else {
    document.getElementById('headerWrap').classList.add('bg');
  }
}

// eslint-disable-next-line no-unused-vars
function GetQueryString() {
  if (document.location.search.length > 1) {
    // 最初の1文字 (?記号) を除いた文字列を取得する
    var query = document.location.search.substring(1);

    // クエリの区切り記号 (&) で文字列を配列に分割する
    var parameters = query.split('&');

    // eslint-disable-next-line no-new-object
    var result = new Object();
    for (var i = 0; i < parameters.length; i++) {
      // パラメータ名とパラメータ値に分割する
      var element = parameters[i].split('=');

      var paramName = decodeURIComponent(element[0]);
      var paramValue = decodeURIComponent(element[1]);

      // パラメータ名をキーとして連想配列に追加する
      result[paramName] = decodeURIComponent(paramValue);
    }
    return result;
  }
  return null;
}

// ============================================================
// ATTENTION & COMMON RULE!!
// まとめて関数実行（※必要に応じて条件分岐を入れる）
// ページ個別に処理をする場合は「ページ固有のID名.lengthで処理を分岐」
// ============================================================

// --- ページの全データを読み込み後 ----------------------------------
$(window).on('load', function() {
  $('.sub-page .head-line').addClass('show');
});

// --- リサイズが走った場合 ----------------------------------------
$(window).on('resize', function() {});

// --- DOM生成後 -----------------------------------------------
$(function() {
  console.log('script.js');

  var observer = lozad('.lozad', {
    loaded: function(el) {
      // Custom implementation on a loaded element

      el.classList.add('loaded');
      if (el.classList.value.indexOf('menu-list') !== -1) {
        console.log(el.classList.value);
        var swiper = new Swiper('#galleryMenu', {
          effect: 'fade',
          pagination: {
            el: '.swiper-pagination'
          },
          speed: 3000,
          autoplay: {
            delay: 3000
          },
          loop: true
        });
      }
      if (el.classList.value.indexOf('menu-list-sp') !== -1) {
        console.log(el.classList.value);
        var swiper = new Swiper('#galleryMenuSp', {
          effect: 'fade',
          pagination: {
            el: '.swiper-pagination'
          },
          speed: 3000,
          autoplay: {
            delay: 3000
          },
          loop: true
        });
      }
    }
  });
  // lazy loads elements with default selector as '.lozad'
  observer.observe({});

  if ($('#topPage').length) {
   
      noIntrofunc();
      console.log('secound');

  } else {
    subPageFunc();
  }
  if($('#formPage1').length){
    formCheck();
  }

  // SMOOTH SCROOL FOR CLASS NAME
  $('a.scroll').click(function() {
    $('html,body').animate(
      { scrollTop: $($(this).attr('href')).offset().top },
      'slow',
      'swing'
    );
    return false;
  });
  // MENU
  $('#menu-trigger').click(function() {
    if ($('body').hasClass('menu-show')) {
      $('body').removeClass('menu-show2');

      setTimeout(function() {
        $('body').removeClass('menu-show');
      }, 300);
    } else {
      $('body').addClass('menu-show');
      setTimeout(function() {
        $('body').addClass('menu-show2');
      }, 300);
    }
  });
  $('.gnav li a').each(function(index) {
    $(this).click(function() {
      $('body').removeClass('menu-show2');

      setTimeout(function() {
        $('body').removeClass('menu-show');
      }, 300);
    });
  });

  // PC or SPサンプル
  if (mq('sp')) {
    console.log('Mobile');
  } else {
    console.log('Desktop');
  }
});
